import React from 'react'
import '../styles/CustomStyles/Navbar.css';
import { NavLink } from 'react-router-dom';
import mytlogo from '../assets/module-zero-logo.png'

function Navbar() {
    return (
      <div className='navbar navbar-expand-lg navbar-light justify-content-between navtext sticky-top' style={{backgroundColor: "#f4f4f4"}}>
        <div className='d-none d-md-block navbar-brand'> 
          <img className='appLogo' src={mytlogo}/>
        </div>
        <div className='d-md-none navbar-brand'> 
          <img className='appLogo' src={mytlogo}/>
        </div>
        <button type="button" className="navbar-toggler me-2" data-bs-toggle="collapse" data-bs-target="#mytNav" aria-controls="mytNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="mytNav">
          <div className='nav navbar-nav mt-2 mt-lg-0'>
            <NavLink to="/" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>Home</NavLink>
            <NavLink to="/about" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>About</NavLink>
            <NavLink to="/testimonials" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>Testimonials</NavLink>
            <NavLink to="/stories" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>Stories</NavLink>
            <NavLink to="/apply" className={({ isActive }) => isActive ? "nav-item active" : "nav-item"}>Apply</NavLink>
          </div>
        </div>
      </div> 
    );
  }
  
export default Navbar;