import { FacebookProvider, CustomChat } from 'react-facebook';

const FacebookMsg = () => {
    return(
        <FacebookProvider appId="336785729353198" chatSupport>
            <CustomChat pageId="102637239410739" minimized={true}/>
        </FacebookProvider> 
    );
};

export default FacebookMsg;