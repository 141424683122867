import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import "../../styles/CustomStyles/StoriesPost.css";

import heroshot from "../../assets/s6/heroshot.jpg"
import img1 from "../../assets/s6/img1.jpg"
import img2 from "../../assets/s6/img2.jpg"

function Stories6() {
    const [heroshotLoaded, setHeroshotLoaded] = useState(false);
    const [img1Loaded, setImg1Loaded] = useState(false);
    const [img2Loaded, setImg2Loaded] = useState(false);

    const handleImageLoad = (setImageLoaded) => {
        setImageLoaded(true);
    };
    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col stories-text text-center'>
                    <h1>MYT welcomes new interns from the <br/> University of Cebu</h1>
                <h6>February 6, 2024 at 11:16 AM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="storiesImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                {!heroshotLoaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${heroshotLoaded ? 'loaded' : 'not-loaded'}`}
                                    src={heroshot}
                                    onLoad={() => handleImageLoad(setHeroshotLoaded)}
                                    style={{ display: heroshotLoaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img1Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img1Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img1}
                                    onLoad={() => handleImageLoad(setImg1Loaded)}
                                    style={{ display: img1Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img2Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img2Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img2}
                                    onLoad={() => handleImageLoad(setImg2Loaded)}
                                    style={{ display: img2Loaded ? 'block' : 'none' }}
                                />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#storiesImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#storiesImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 stories-text'>
                    <p>Exciting times ahead as we extend a warm welcome to the interns from UC Banilad and UC Main! We can't wait to see all the amazing things our interns will achieve during their time with us. 🤗
                    <br/> <br/> Welcome to the team!</p>
                    <p><a href='https://www.facebook.com/hashtag/modulezero' target='_blank' className='stories-link'>
                        #ModuleZero </a>
                        <a href='https://www.facebook.com/hashtag/internship' target='_blank' className='stories-link'>
                        #Internship </a>
                        <a href='https://www.facebook.com/hashtag/learnwithmyt' target='_blank' className='stories-link'>
                        #LearnwithMYT</a>
                        <a href='https://www.facebook.com/hashtag/careerdevelopment' target='_blank' className='stories-link'>
                        #CareerDevelopment </a>
                        <a href='https://www.facebook.com/hashtag/mytculture' target='_blank' className='stories-link'>
                        #MYTCulture </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Stories6;