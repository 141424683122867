import React from 'react';
import home_pc from '../assets/home-kb.png';
import '../styles/CustomStyles/Home.css';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <>
      <div className='container-fluid my-5 px-4'>
        <div className='row align-items-center justify-content-lg-center justify-content-md-around pb-3'>
          <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 ms-lg-5 ps-lg-5 home-text'>
            <h1 className='text-center text-md-start'>EMPOWERING <br></br> <span class='dark-text'>FUTURE</span> <br></br> INNOVATORS.</h1>
            <br></br>
            <img className='d-md-none mb-5 img-fluid mx-auto d-block home-image' src={home_pc}/>
            <p>
              Module Zero is ready and set to train you to the wonders of the programming world. <br></br><br></br>
              We aim to foster a <span class='bold'>collaborative learning environment</span> where you can connect with peers and mentors. {/* and gain the confidence to launch your career. */}
            </p>
          </div>
          <div className='d-none d-md-block col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 ms-lg-5'>
            <img className='img-fluid home-image text-center text-md-start' src={home_pc} data-aos="zoom-in" data-aos-delay="200"/>
          </div>
        </div>
        <div className='d-flex flex-row justify-content-center mt-2'>
          <Link to="/about"><button type='button' className='btn btn-learn'>Learn more</button></Link>
        </div>
      </div>
    </>
  );
}

export default Home;