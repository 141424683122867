import React, {useEffect} from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import Apply from './pages/Apply';
import Testimonials from './pages/Testimonials';

import Stories from './pages/Stories/Stories';
import Stories1 from './pages/Stories/Stories1';
import Stories2 from './pages/Stories/Stories2';
import Stories3 from './pages/Stories/Stories3';
import Stories4 from './pages/Stories/Stories4';
import Stories5 from './pages/Stories/Stories5';
import Stories6 from './pages/Stories/Stories6';
import Stories7 from './pages/Stories/Stories7';
import Stories8 from './pages/Stories/Stories8';

// Import AOS
import 'aos/dist/aos.css';
import AOS from 'aos';

// CSS
// import "./styles/Landing.css";
import "./styles/CommonStyles/Common.css";
import "./styles/CommonStyles/Responsiveness.css";
import "./App.css";

import FacebookMsg from "./components/FacebookMsg";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, once: true
    });
  }, []);

  return (
    <>
      <div className='app'>
        <Router>
          <ScrollToTop />
          <Navbar /> 
          <div className='main'>
            <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/about" element={<AboutUs/>} />
              <Route path="/testimonials" element={<Testimonials/>} />
              <Route path="/stories" element={<Stories/>}/>
              <Route path="/stories/stories1" element={<Stories1/>}/>
              <Route path="/stories/stories2" element={<Stories2/>}/>
              <Route path="/stories/stories3" element={<Stories3/>}/>
              <Route path="/stories/stories4" element={<Stories4/>}/>
              <Route path="/stories/stories5" element={<Stories5/>}/>
              <Route path="/stories/stories6" element={<Stories6/>}/>
              <Route path="/stories/stories7" element={<Stories7/>}/>
              <Route path="/stories/stories8" element={<Stories8/>}/>
              <Route path="/apply" element={<Apply/>}/>
            </Routes>
          </div>
          <Footer />
        </Router>
        
        <FacebookMsg />
      </div>
    </>
  );
};

export default App;
