import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import "../styles/CustomStyles/Apply.css";
import apply_icon from '../assets/apply.png';

function Apply() {
  const [iconLoaded, setIconLoaded] = useState(false);

  const handleIconLoad = () => {
    setIconLoaded(true);
  };

  const handleImageLoad = (setImageLoaded) => {
    setImageLoaded(true);
  };

  return (
    <>
      <div className='vertical-center'>
        <div className='container'>
          <div className='row align-items-center justify-content-center px-5 px-md-0 my-5 my-md-0'>            
            <div className='d-none d-md-block col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5'>
              {!iconLoaded && <Skeleton variant="rectangular" width={450} height={400} style={{ borderRadius: 10 }} />}
              <img
                className={`img-fluid mx-auto d-block apply-icon ${iconLoaded ? 'loaded' : 'not-loaded'}`}
                src={apply_icon}
                onLoad={handleIconLoad}
                alt="Module Zero Icon"
                style={{ display: iconLoaded ? 'block' : 'none' }}
              />
            </div> 

            <div className='col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 offset-md-2 apply-text'>
              <br></br>
              <h1 className='text-center text-md-start'>Join us!</h1>
              <br></br>
              <img className='d-md-none mb-5 img-fluid mx-auto d-block apply-icon' src={apply_icon}/>
              <div className='text-center text-md-start'>
                <p>Take the first step towards your tech future! Apply now for Module Zero Internship and embark on a transformative journey in software technology.</p>
                <br></br>
                <p><a href='https://goo.gl/maps/1M4wKeRbV6s73z1N9' className='apply-info' target='_blank'><span style={{fontWeight:'600'}}>Address: </span> 
                    301 The Greenery, Pope John Paul II Avenue, Kasambagan, Cebu City, 
                    Philippines</a></p>
                <p><a href="tel:+639173276230" className='apply-info' target='_blank'><span style={{fontWeight:'600'}}>Contact number:</span> +63 917 327 6230</a></p>
                <br></br>

                <a href='mailto:careers@mytdevcorp.com' target='_blank' style={{color:'inherit'}}>
                <button type='button' className='btn btn-apply'>
                  <i class="bi bi-envelope-at"></i> careers@mytdevcorp.com
                </button>
                </a>
              </div>
            </div>
          </div>          
        </div>
      </div>
      <br></br>
    </>
  );
}

export default Apply;