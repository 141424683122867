import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import "../../styles/CustomStyles/StoriesPost.css";

import heroshot from "../../assets/s4/heroshot.jpg"
import img1 from "../../assets/s4/img1.jpg"
import img2 from "../../assets/s4/img2.jpg"
import img3 from "../../assets/s4/img3.jpg"
import img4 from "../../assets/s4/img4.jpg"

function Stories4() {
    const [heroshotLoaded, setHeroshotLoaded] = useState(false);
    const [img1Loaded, setImg1Loaded] = useState(false);
    const [img2Loaded, setImg2Loaded] = useState(false);
    const [img3Loaded, setImg3Loaded] = useState(false);
    const [img4Loaded, setImg4Loaded] = useState(false);

    const handleImageLoad = (setImageLoaded) => {
        setImageLoaded(true);
    };

    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col stories-text text-center'>
                    <h1>UP Interns Completion 👏</h1>
                <h6>August 30, 2023 at 05:10 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="storiesImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                {!heroshotLoaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${heroshotLoaded ? 'loaded' : 'not-loaded'}`}
                                    src={heroshot}
                                    onLoad={() => handleImageLoad(setHeroshotLoaded)}
                                    style={{ display: heroshotLoaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img1Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img1Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img1}
                                    onLoad={() => handleImageLoad(setImg1Loaded)}
                                    style={{ display: img1Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img2Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img2Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img2}
                                    onLoad={() => handleImageLoad(setImg2Loaded)}
                                    style={{ display: img2Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img3Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img3Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img3}
                                    onLoad={() => handleImageLoad(setImg3Loaded)}
                                    style={{ display: img3Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img4Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img4Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img4}
                                    onLoad={() => handleImageLoad(setImg4Loaded)}
                                    style={{ display: img4Loaded ? 'block' : 'none' }}
                                />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#storiesImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#storiesImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 stories-text'>
                    <p>Congratulations to the interns from UP Cebu Department of Computer Science for completing the Module Zero Internship program 👏. Your commitment to learning, passion for growth, and dedication have paid off. 
                    <br/> <br/> As you step into a new chapter, remember that this is just the beginning to your extraordinary journey.
                    <br/> <br/> Here's to embracing the challenges and seizing the opportunities that lie ahead! 🚀🌈</p>
                    <p><a href='https://www.facebook.com/hashtag/modulezero' target='_blank' className='stories-link'>
                        #ModuleZero </a>
                        <a href='https://www.facebook.com/hashtag/UPcebuinterns' target='_blank' className='stories-link'>
                        #UPCebuInterns </a>
                        <a href='https://www.facebook.com/hashtag/continuouslearning' target='_blank' className='stories-link'>
                        #ContinuousLearning </a>
                        <a href='https://www.facebook.com/hashtag/careergrowth' target='_blank' className='stories-link'>
                        #CareerGrowth </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Stories4;