import React from 'react'
import '../styles/CustomStyles/Footer.css';
//import mytFooterLogo from '../assets/myt logo.png'
import new_logo from '../assets/module-zero-logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function Footer() {
  return (
    <footer className="d-flex justify-content-between" style={{backgroundColor: "#f4f4f4"}}>
      <div class="d-flex align-items-center">
        <img className='footerLogo' src={new_logo}/>
        {/* <span className='footerTitle d-none d-md-block'>MODULE 0</span> */}
      </div>
      <ul class="col-md-4 align-items-center justify-content-end list-unstyled d-flex ms-2 ms-md-0 me-md-5">
        <li><a target="_blank" href='https://www.facebook.com/modulezero'><FontAwesomeIcon className='footerIcon' icon={faFacebook}/></a></li>
        <li><a target="_blank" href='mailto:careers@mytdevcorp.com'><FontAwesomeIcon className='footerIcon' icon={faEnvelope}/></a></li>
      </ul>
    </footer>
  );
}

export default Footer;
