import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import "../../styles/CustomStyles/StoriesPost.css";

import heroshot from "../../assets/s3/heroshot.jpg"
import img1 from "../../assets/s3/img1.jpg"
import img2 from "../../assets/s3/img2.jpg"
import img3 from "../../assets/s3/img3.jpg"
import img4 from "../../assets/s3/img4.jpg"
import img5 from "../../assets/s3/img5.jpg"
import img6 from "../../assets/s3/img6.jpg"
import img7 from "../../assets/s3/img7.jpg"
import img8 from "../../assets/s3/img8.jpg"
import img9 from "../../assets/s3/img9.jpg"
import img10 from "../../assets/s3/img10.jpg"
import img11 from "../../assets/s3/img11.jpg"
import img12 from "../../assets/s3/img12.jpg"
import img13 from "../../assets/s3/img13.jpg"
import img14 from "../../assets/s3/img14.jpg"

function Stories3() {
    const [heroshotLoaded, setHeroshotLoaded] = useState(false);
    const [img1Loaded, setImg1Loaded] = useState(false);
    const [img2Loaded, setImg2Loaded] = useState(false);
    const [img3Loaded, setImg3Loaded] = useState(false);
    const [img4Loaded, setImg4Loaded] = useState(false);
    const [img5Loaded, setImg5Loaded] = useState(false);
    const [img6Loaded, setImg6Loaded] = useState(false);
    const [img7Loaded, setImg7Loaded] = useState(false);
    const [img8Loaded, setImg8Loaded] = useState(false);
    const [img9Loaded, setImg9Loaded] = useState(false);
    const [img10Loaded, setImg10Loaded] = useState(false);
    const [img11Loaded, setImg11Loaded] = useState(false);
    const [img12Loaded, setImg12Loaded] = useState(false);
    const [img13Loaded, setImg13Loaded] = useState(false);
    const [img14Loaded, setImg14Loaded] = useState(false);

    const handleImageLoad = (setImageLoaded) => {
        setImageLoaded(true);
    };

    return (
        <div className='container-fluid my-5'>
            <div className='row mb-5'>
                <div className='col stories-text text-center'>
                    <h1>Interns one-month mark meeting</h1>
                <h6>June 14, 2023 at 05:10 PM</h6>
                </div>
            </div>

            <div className='row align-items-center justify-content-center mx-5 gx-5 gy-5'>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5'>
                    <div id="storiesImages" className="carousel carousel-dark slide mx-auto" data-bs-ride="true" data-bs-interval='4000'>
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="5" aria-label="Slide 6"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="6" aria-label="Slide 7"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="7" aria-label="Slide 8"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="8" aria-label="Slide 9"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="9" aria-label="Slide 10"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="10" aria-label="Slide 11"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="11" aria-label="Slide 12"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="12" aria-label="Slide 13"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="13" aria-label="Slide 14"></button>
                            <button type="button" data-bs-target="#storiesImages" data-bs-slide-to="14" aria-label="Slide 15"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                {!heroshotLoaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${heroshotLoaded ? 'loaded' : 'not-loaded'}`}
                                    src={heroshot}
                                    onLoad={() => handleImageLoad(setHeroshotLoaded)}
                                    style={{ display: heroshotLoaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img1Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img1Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img1}
                                    onLoad={() => handleImageLoad(setImg1Loaded)}
                                    style={{ display: img1Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img2Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img2Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img2}
                                    onLoad={() => handleImageLoad(setImg2Loaded)}
                                    style={{ display: img2Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img3Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img3Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img3}
                                    onLoad={() => handleImageLoad(setImg3Loaded)}
                                    style={{ display: img3Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img4Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img4Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img4}
                                    onLoad={() => handleImageLoad(setImg4Loaded)}
                                    style={{ display: img4Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img5Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img5Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img5}
                                    onLoad={() => handleImageLoad(setImg5Loaded)}
                                    style={{ display: img5Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img6Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img6Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img6}
                                    onLoad={() => handleImageLoad(setImg6Loaded)}
                                    style={{ display: img6Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img7Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img7Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img7}
                                    onLoad={() => handleImageLoad(setImg7Loaded)}
                                    style={{ display: img7Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img8Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img8Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img8}
                                    onLoad={() => handleImageLoad(setImg8Loaded)}
                                    style={{ display: img8Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img9Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img9Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img9}
                                    onLoad={() => handleImageLoad(setImg9Loaded)}
                                    style={{ display: img9Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img10Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img10Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img10}
                                    onLoad={() => handleImageLoad(setImg10Loaded)}
                                    style={{ display: img10Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img11Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img11Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img11}
                                    onLoad={() => handleImageLoad(setImg11Loaded)}
                                    style={{ display: img11Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img12Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img12Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img12}
                                    onLoad={() => handleImageLoad(setImg12Loaded)}
                                    style={{ display: img12Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img13Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img13Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img13}
                                    onLoad={() => handleImageLoad(setImg13Loaded)}
                                    style={{ display: img13Loaded ? 'block' : 'none' }}
                                />
                            </div>
                            <div className="carousel-item">
                                {!img14Loaded && <Skeleton animation="wave" variant="rectangle" width={490} height={415} style={{ borderRadius: 20 }} />}
                                <img
                                    className={`stories-img ${img14Loaded ? 'loaded' : 'not-loaded'}`}
                                    src={img14}
                                    onLoad={() => handleImageLoad(setImg14Loaded)}
                                    style={{ display: img14Loaded ? 'block' : 'none' }}
                                />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#storiesImages" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#storiesImages" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 stories-text'>
                    <p>We are proud to congratulate our Module 0 Batch 5 interns on their graduation! 🎓💪 Throughout their time with us, they've demonstrated unwavering dedication, a thirst for knowledge, and a true passion for their chosen field. 
                    <br/> <br/> We would also like to congratulate Emma for being an outstanding intern from the Batch 5. 👏
                    <br/> <br/> Their graduation marks the beginning of a bright and promising future, and we are excited to witness the incredible things they will achieve. We wish them all the best in their future endeavors. 🌟🎉</p>
                    <p><a href='https://www.facebook.com/hashtag/modulezero' target='_blank' className='stories-link'>
                        #ModuleZero </a>
                        <a href='https://www.facebook.com/hashtag/internship' target='_blank' className='stories-link'>
                        #Internship </a>
                        <a href='https://www.facebook.com/hashtag/learnwithMYT' target='_blank' className='stories-link'>
                        #LearnwithMYT </a>
                        <a href='https://www.facebook.com/hashtag/careerdevelopment' target='_blank' className='stories-link'>
                        #CareerDevelopment </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Stories3;