import React, { useState, useEffect } from 'react';
import "../../styles/CustomStyles/Stories.css";
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

import s1_img from '../../assets/s1/heroshot.jpg';
import s2_img from '../../assets/s2/heroshot.jpg';
import s3_img from '../../assets/s3/heroshot.jpg';
import s4_img from '../../assets/s4/heroshot.jpg';
import s5_img from '../../assets/s5/heroshot.jpg';
import s6_img from '../../assets/s6/heroshot.jpg';
import s7_img from '../../assets/s7/heroshot.jpg';
import s8_img from '../../assets/s8/heroshot.jpg';

function Stories() {
  const [loading, setLoading] = useState(true);
  const [postInfo, setPostInfo] = useState([]);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
      // Define your post information dynamically
      setPostInfo([
        { date: "2024, June 27", title: "Take a look at our incredible Module Zero Batch 9 interns!", link: "/stories/stories8" },
        { date: "2024, May 8", title: "Remember the interns from UC Banilad and UC Main?", link: "/stories/stories7" },
        { date: "2024, February 6", title: "MYT welcomes new interns from the University of Cebu", link: "/stories/stories6" },
        { date: "2023, December 13", title: "Internship Fair at University of Cebu LM - Campus 🎓✨", link: "/stories/stories5" },
        { date: "2023, August 30", title: "UP Interns Completion 👏", link: "/stories/stories4" },
        { date: "2023, June 14", title: "Interns graduation! 🎓", link: "/stories/stories3" },
        { date: "2023, March 15", title: "Interns one-month mark meeting!", link: "/stories/stories1" },
        { date: "2023, February 16", title: "Orientation - Exciting times ahead! 🎉", link: "/stories/stories2" }
      ]);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      {/* <div></div> */}

      <header className="section-header pb-5 pt-5 text-center">
        <h2>Our Stories</h2>
      </header>

      <section id="recent-stories-posts" className="recent-stories-posts">

      <div className="container" data-aos="fade-up">

        <div className="row gy-5 pb-5 justify-content-center">

          {[...Array(8)].map((_, index) => (
            <div className="col-lg-4" key={index}>
              <div className="post-box">
                <div className="post-img">
                  <Skeleton animation="wave" variant="rectangular" width={450} height={310} style={{ display: loading ? 'block' : 'none' }} />
                  <img src={index === 0 ? s8_img : index === 1 ? s7_img : index === 2 ? s6_img : index === 3 ? s5_img : index === 4 ? s4_img : index === 5 ? s3_img : index === 6 ? s1_img : s2_img} className="img-fluid" alt="" style={{ display: loading ? 'none' : 'block' }} />
                </div>
                  <>
                    <Skeleton animation="wave" height={20} width="50%" style={{ marginBottom: 8, display: loading ? 'block' : 'none' }} />
                    <Skeleton animation="wave" height={30} style={{ marginBottom: 0, display: loading ? 'block' : 'none' }} />
                    <Skeleton animation="wave" height={30} width="80%" style={{ marginBottom: 8, display: loading ? 'block' : 'none' }} />
                    <Skeleton animation="wave" height={45} width="35%" style={{ marginBottom: 8, borderRadius: 20, display: loading ? 'block' : 'none' }} />
                  </>
                  {!loading && (
                    <>
                      <span className="post-date">{postInfo[index].date}</span>
                      <h3 className="post-title">{postInfo[index].title}</h3>
                      <Link to={postInfo[index].link} className="readmore stretched-link mt-auto"><button type='button' className='btn btn-primary btn-blog'>Read More <i className="bi bi-arrow-right"></i></button></Link>
                    </>
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </>
  );
}

export default Stories;